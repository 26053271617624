document.addEventListener("DOMContentLoaded", () => {
	const testForm = document.querySelector("#pass-test-form");
	const buttonProfileRequestCertificate = document.querySelector("#profile-request-certificate")
	const popupBg = document.querySelector(".popup__bg");
	if (testForm) {
	testForm.addEventListener('submit', async (evt) => {
		evt.preventDefault();

		const address = `/test/check/`;
		const popup = popupBg.querySelector(".popup");

		openPopup(popup, popupBg)

		$.ajax({
			url: address,
			type: 'POST',
			data: new FormData(testForm),
			processData: false,
			contentType: false,
			statusCode: {
				// тест не заполнен
				422: function(response) {
					popupBg.innerHTML = popupBase;
					const popupTitle = popupBg.querySelector(".popup__title");
					const popupWrap = popupBg.querySelector(".popup__wrap");
					const popupClose = popupBg.querySelector(".popup__close");
					popupClose.addEventListener("click", () => { closeTestPopup(popup, popupBg) })
					popupTitle.innerText = `Тест не пройден!`;
					popupWrap.innerHTML = response.responseText;
				},
				//
				400: function(response) {
					popupBg.innerHTML = popupBase;
					const popupTitle = popupBg.querySelector(".popup__title");
					const popupWrap = popupBg.querySelector(".popup__wrap");
					const popupClose = popupBg.querySelector(".popup__close");
					popupClose.addEventListener("click", () => { closeTestPopup(popup, popupBg) })
					popupTitle.innerText = `Тест не сдан!`;
					popupWrap.innerHTML = response.responseText;
					setTimeout(() => {
						testForm.reset();
					}, 1000)
				},
				// успешный тест
				200: function (response) {
					popupBg.innerHTML = popupBase;
					const popupTitle = popupBg.querySelector(".popup__title");
					const popupClose = popupBg.querySelector(".popup__close");
					popupClose.addEventListener("click", () => {
						console.log('Выполнение 200')
						closeTestPopup(popup, popupBg);
						location.reload();
					});
					popupTitle.innerText = `Тест сдан!`;
					setTimeout(() => {
						location.reload();
					}, 5000)
				},
				// успешный тест и нужно отдавать сертификат сертификат
				222: function (response) {
					popupBg.innerHTML = popupBase;
					const popupTitle = popupBg.querySelector(".popup__title");
					const popupWrap = popupBg.querySelector(".popup__wrap");
					const popupClose = popupBg.querySelector(".popup__close");
					popupClose.addEventListener("click", () => {
						console.log('Выполнение 222')
						closeTestPopup(popup, popupBg);
						location.reload();
					});
					popupTitle.innerText = `Тест сдан!`;
					popupWrap.innerHTML = response;

					const certificateForm = document.querySelector("#request-certificate-form");
					if (certificateForm) {
						certificateForm.addEventListener('submit', async (evt) => {
							evt.preventDefault();
							const address = `/test/certificate-request/`;
							const popup = popupBg.querySelector(".popup");
							openPopup(popup, popupBg)
							$.ajax({
								url: address,
								type: 'POST',
								data: new FormData(certificateForm),
								processData: false,
								contentType: false,
								success: function (response) {
										popupBg.innerHTML = popupBase;
										const popupTitle = popupBg.querySelector(".popup__title");
										const popupWrap = popupBg.querySelector(".popup__wrap");
										const popupClose = popupBg.querySelector(".popup__close");
										popupClose.addEventListener("click", () => {
											closeTestPopup(popup, popupBg)
										})
										popupTitle.innerText = `Готово!`;
										popupWrap.innerHTML = response;
										setTimeout(() => {
											location.reload();
										}, 5000)
									}
							});
						})
					}
				}
			}
		});
	})
	}
	if(buttonProfileRequestCertificate) {
		buttonProfileRequestCertificate.addEventListener('click', async (evt) => {
				evt.preventDefault();
				const address = buttonProfileRequestCertificate.getAttribute('href');
				const popup = popupBg.querySelector(".popup");
				openPopup(popup, popupBg)
				popupBg.innerHTML = popupBase;
				const popupTitle = popupBg.querySelector(".popup__title");
				const popupWrap = popupBg.querySelector(".popup__wrap");
				const popupClose = popupBg.querySelector(".popup__close");
				popupClose.addEventListener("click", () => {
					closeTestPopup(popup, popupBg);
				});
				popupTitle.innerText = `Запрос на получение сертификата`;
				popupWrap.innerHTML = "";

				$.ajax({
					url: "/test/certificate-request-form/",
					type: 'GET',
					processData: false,
					contentType: false,
					success: function (response) {
						popupWrap.innerHTML = response;
						const profileCertificateForm = document.querySelector("#request-certificate-form");
						if (profileCertificateForm) {
							profileCertificateForm.addEventListener('submit', async (evt) => {
								evt.preventDefault();
								const address = `/test/certificate-request/`;
								const popup = popupBg.querySelector(".popup");
								openPopup(popup, popupBg)
								$.ajax({
									url: address,
									type: 'POST',
									data: new FormData(profileCertificateForm),
									processData: false,
									contentType: false,
									success: function (responseCertificate) {
										popupBg.innerHTML = popupBase;
										const popupTitle = popupBg.querySelector(".popup__title");
										const popupWrap = popupBg.querySelector(".popup__wrap");
										const popupClose = popupBg.querySelector(".popup__close");
										popupClose.addEventListener("click", () => {
											closeTestPopup(popup, popupBg)
										})
										popupTitle.innerText = `Готово!`;
										popupWrap.innerHTML = responseCertificate;
										setTimeout(() => {
											location.reload();
										}, 5000)
									}
								});
							})
						}
					}
				});
			}
		)
	}
})

const popupBase = `<div class="popup active">
    <div class="popup__scroll">
      <div class="popup__inner">
        <div class="popup__close js-popup-close">
          <img src="/frontend_dist/assets/img/close.svg" alt="close popup"/>
        </div>
        <div class="popup__title"></div>
        <div class="popup__wrap"></div>
      </div>
    </div>
  </div>`
const popupLoader = `<div class="popup popup--loader"><img class="popup__loader" src="/frontend_dist/assets/img/loader.svg" alt="loader" id="loader"></div>`;

function openPopup(popup, popupBg) {
	popup.classList.add("active");
	popupBg.classList.add("active");
}

function closeTestPopup(popup, popupBg) {
	popupBg.classList.remove("active");
	popup.classList.remove("active");

	setTimeout(() => {
		console.log('resetModal');
		popupBg.innerHTML = popupLoader
	}, 500)
}
